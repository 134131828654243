import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

function calcDiff(date1, date2) {
  const diffMs = (new Date(date1).getTime() - new Date(date2).getTime());
  const diffMins = Math.round(diffMs / 60000);
  return diffMins;
}

const columns = [
  { label: 'User', name: 'accountId', valueType: 'string', url: 'https://cp.bbintern.net/akutbolig/users', link: 'accountId' },
  { label: 'Created', name: 'subscriptionCreated', valueType: 'Date' },
  { label: 'Google Click ID', name: 'gclid', valueType: 'string' },
  { label: 'Clicked', name: 'timestamp', valueType: 'Date' },
  { label: 'Time difference', name: 'timeDiff', valueType: 'number', suffix: 'minutes' }
];

function parseDate(date) {
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
}

export const GoogleClickReport = (props: Props): JSX.Element => {
    const now = new Date();
    const past = new Date(new Date().getTime() - 90 * ONE_DAY);
    const [list, setList] = React.useState([]);
    const [from, setFrom] = React.useState(parseDate(past));
    const [to, setTo] = React.useState(parseDate(now));

    const [accounts, setAccounts] = React.useState(0);
    const [metas, setMetas] = React.useState(0);
    const [match, setMatch] = React.useState('subscription');

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
         apiClient.post('/api/microservice', { data: { action: 'getGoogleClicksReport', data: { filter: { from: from, to: to, match: match } } } }).then((data) => {
          if(data) {
            const { accounts, metas, result } = data;
              setList(result);
              setAccounts(accounts);
              setMetas(metas);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div>
        <span>From: </span>
        <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
        <span>To: </span>
        <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
        <span>Match: </span>
        <Select value={match} onChange={(ev) => setMatch(ev.target.value)}>
          <MenuItem value="subscription">Subscriptions</MenuItem>
          <MenuItem value="userSignUp">User signups</MenuItem>
        </Select>
        <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No clicks that matches</p> : ( <Table
            title={`Google Clicks Report (Matches: ${list?.length}${accounts ? ` | Accounts: ${accounts}`: ''}${metas ? ` | Accounts: ${metas}`: ''})`}
            columns={columns}
            rows={list}
        />)}
    </div>
  );
};
