import React from 'react';
import FormInput from '../FormInput';

const MatchSelection = (props) => {
    const [value, setValue] = React.useState(props.value);

  return (<FormInput
        key={props.name}
        name={props.name}
        label={props.name?.toUpperCase()}
        value={value}
        type={'text'}
        labelSm={3}
        valueSm={8}
        onChange={(ev) => {
            setValue(ev.target.value);
            props.onChange(props.name, ev.target.value);
        }}
        reset={(ev) => {
            setValue(null);
            props.onChange(props.name, null);
        }}
    />
  );
};

export default MatchSelection;
