import React, { useState } from 'react';
import LinkModal from './LinkModal';
import FileUpload from './FileUpload';
import LinksUpload from './LinksUpload';
import { Checkbox, makeStyles } from '@material-ui/core';
import Filter from '../Inputs/Filter';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const LinkUpload = (props) => {
  const classes = useStyles({});
  const [category, setCategory] = useState(null);
  const [overwrite, setOverwrite] = useState(true);

  return (
    <LinkModal open={props.open} onClose={props.onRequestClose}>
      <h2 id="simple-modal-title" style={{ marginBottom: '1em' }}>
        {props.title}
      </h2>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Filter
          filterValue={category}
          categories={props.categories || []}
          setFilterParam={(name, value) => setCategory(value)}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>Overwrite on update:</label>
          <Checkbox
            checked={overwrite}
            onChange={() => setOverwrite(!overwrite)}
          />
        </div>
      </div>
      <hr />
      <FileUpload
        classes={classes}
        inputLabel="Upload file"
        onSuccess={props.onSuccess}
        onError={props.onError}
        category={category}
        overwrite={overwrite}
      />
      <hr />
      <LinksUpload
        classes={classes}
        onSuccess={props.onSuccess}
        onError={props.onError}
        category={category}
        overwrite={overwrite}
      />
    </LinkModal>
  );
};

export default LinkUpload;
