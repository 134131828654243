import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, compose, gql } from 'react-apollo';
import update from 'immutability-helper';
import userQuery from './user.graphql';
import mutateUserQuery from './mutateUser.graphql';
import mutateAlertQuery from './mutateUserAlert.graphql';

// import { Form, FormInput } from '../../common/form';
import Form from '../common/Form';
import FormInput from '../common/FormInput';
// import FormCellphone from '../common/FormCellphone';
import {
  Grid,
  Row,
  Col,
  Panel as BootPanel,
  Table,
  Button
} from 'react-bootstrap';
import ConfirmationButton from '../common/confirmation/button';

import { USER_STATUS_DELETED, FIELDS, formatDateStr } from './user-helper';

import SubPanels from './panels/sub-panels';
import AuditPanel from './panels/audit';
import LeaseAccessPanel from './panels/lease-access';
import LeaseUnwantedPanel from './panels/lease-unwanted';
import SubscriptionPanel from './panels/subscription';
import NotificationTargetsPanel from './panels/NotificationTargets';
import UserMetaPanel from './panels/user-meta';
import Loading from '../common/loading';
import { GET_SUBSCRIPTIONS, GET_USER } from './fragements';
import { fetcher } from 'app/utils/fetcher';
import RentalPreference from './panels/RentalPrefernce';
import Panel from './Panel';
import apiClient from 'app/utils/api-client';
import SessionsPanel from './panels/sessions';

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function addDKCharacters(str) {
  return str.replace(/ae/g, 'æ').replace(/oe/g, 'ø').replace(/aa/g, 'å');
}

export function serialzSub(data) {
  if (!data) {
    return null;
  }
  return data.map((d) => ({
    ...d,
    renew: Boolean(!d.canceledAt)
  }));
}

class AkutboligUser extends Component {
  static propTypes = {
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      user: null,
      subscription: null,
      loading: false
    };

    if (props.user?.id) {
      this.state.loading = true;
      this.getUser(props.user.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user?.id && nextProps.user.id !== this.props.user?.id) {
      this.setState({ loading: true });
      this.getUser(nextProps.user.id);
    }
  }

  getUser = (id) => {
    Promise.all([
      fetcher('/api/akutbolig/graph-proxy', {
        query: GET_USER,
        variables: { id }
      })
        .then((data) => {
          this.setState({ user: data?._admin_user });
        })
        .catch((err) => console.log(err)),
      fetcher('/api/akutbolig/graph-proxy', {
        query: GET_SUBSCRIPTIONS,
        variables: { ids: [id] }
      })
        .then((data) => {
          this.setState({
            subscriptions: serialzSub(data?._admin_subscriptions)
          });
        })
        .catch((err) => console.log(err))
    ]).then(() => {
      this.setState({ loading: false });
    });
  };

  reload = (data) => {
    this.setState({ reload: true }, () => this.setState({ reload: false }));
    return data;
  };

  deleteUser = (confirmed, values) => {
    this.props
      .deleteUser({ reason: values.notes || ''})
      .then(this.reload)
      .catch((error) => alert(error));
  };
  undoDelete = (confirmed, values) => {
    this.props
      .undoDelete({ reason: values.notes || '' })
      .then(this.reload)
      .catch((error) => alert(error));
  };

  handleSubmit = (values) => {
    if (!values.size) {
      return false;
    }

    const data = {};
    values.forEach((value, key) => {
      data[key] = key === 'cellphone' ? Number(value) : value;
    });

    this.props
      .updateUser(data)
      .then(this.reload)
      .catch((error) => alert(error));
  };

  deactivateAlerts = () => {
    return Promise.all(
      this.props.user.alerts
        .filter((a) => a.status === 'ACTIVE')
        .map((alert) => {
          return this.props
            .updateAlert(alert.id, { status: 'INACTIVE' })
            .catch((error) => alert(error));
        })
    );
  };

  toggleAlert = (ev) => {
    const alertId = Number(ev.target.value);
    const alert = this.props.user.alerts.filter((a) => a.id === alertId)[0];
    this.props
      .updateAlert(alert.id, {
        status: alert.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      })
      .catch((error) => alert(error));
  };

  render() {
    // const { user } = this.state;
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const inputs = Object.keys(FIELDS).map((key) => {
      const { transform, ...props } = FIELDS[key];
      const value = user[key];

      return (
        <FormInput
          key={key}
          name={key}
          defaultValue={transform ? transform(value) : value}
          {...props}
        />
      );
    });

    if (!user || this.state.reload) {
      return null;
    }
    const activeAlerts = user?.alerts.filter((a) => a.status === 'ACTIVE')
      .length;
    const activeTargets = this.state.user?.notificationTargets.filter(
      (item) => item.active
    ).length;
    return (
      <div>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <Form onSubmit={this.handleSubmit} submitTxt="Update user">
                {inputs}
              </Form>

              <hr />

              {user.emailStatus !== USER_STATUS_DELETED ? (
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-4">
                    <ConfirmationButton
                      onSubmit={this.deleteUser}
                      label="Delete user?"
                      text="Please confirm, you want to delete the user"
                      className="pull-right"
                    >
                      <textarea
                        className="form-control"
                        name="notes"
                        rows="2"
                        required
                        minLength="10"
                      />
                    </ConfirmationButton>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-4">
                    <ConfirmationButton
                      onSubmit={this.undoDelete}
                      label="Undo Delete?"
                      text="Please confirm, you want to undo the deletion"
                      className="pull-right"
                    >
                      <textarea
                        className="form-control"
                        name="notes"
                        rows="2"
                        required
                        minLength="10"
                      />
                    </ConfirmationButton>
                  </div>
                </div>
              ) }
              <hr />
              {this.state.loading ? (
                <Loading />
              ) : (
                <>
                  <Panel
                    bsStyle={activeTargets ? 'success' : 'default'}
                    collapsible
                    expanded
                    header={
                      <h4>
                        Notifications ({activeTargets}/
                        {this.state.user?.notificationTargets?.length})
                      </h4>
                    }
                    list={this.state.user?.notificationTargets}
                    ItemComponent={NotificationTargetsPanel}
                  />
                  <RentalPreference user={this.state.user} />
                </>
              )}
            </Col>

            <Col sm={6}>
              <UserMetaPanel user={this.state.user} />
              <AuditPanel title={'Audit log'} trail={user?.audits} user={user} />
              <AuditPanel title={'Notification log'} trail={user?.notificationAudit} user={user} />
              <LeaseAccessPanel list={this.state.user?.leaseAccess} />
              <LeaseUnwantedPanel list={this.state.user?.leaseUnwanted} />
              <SessionsPanel list={this.state.user?.sessions} />

              {this.state.loading ? (
                <Loading />
              ) : this.state.subscriptions ? (
                <SubPanels
                  header="Subscriptions"
                  collapsible
                  defaultExpanded
                  list={this.state.subscriptions?.sort((a, b) => new Date(b.updatedAt || b.createdAt).getTime() - new Date(a.updatedAt || a.createdAt).getTime())}
                  ItemPanel={SubscriptionPanel}
                />
              ) : null}

              {user?.alerts.length ? (
                <BootPanel
                  collapsible
                  expanded
                  bsStyle={activeAlerts ? 'success' : 'default'}
                  header={`Notifications (${activeAlerts}/${user?.alerts.length})`}
                >
                  {user?.alerts.map((alert) => {
                    const active = alert.status === 'ACTIVE';
                    return (
                      <BootPanel
                        collapsible
                        bsStyle={active ? 'success' : 'warning'}
                        header={capitalizeFirstLetter(
                          addDKCharacters(alert.area)
                        )}
                        key={alert.id}
                      >
                        <Table striped condensed responsive hover fill>
                          <tbody>
                            <tr>
                              <th style={{ width: '40%' }}>Status</th>
                              <td>
                                {active ? (
                                  <i
                                    className="fa fa-check-circle"
                                    style={{ color: 'darkGreen' }}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-times-circle"
                                    style={{ color: 'rgb(220, 0, 0)' }}
                                  />
                                )}
                                &nbsp;
                                {active ? 'Active' : 'Inactive'}
                                <Button
                                  className="pull-right"
                                  bsSize="xsmall"
                                  bsStyle={active ? 'danger' : 'success'}
                                  value={alert.id}
                                  onClick={this.toggleAlert}
                                >
                                  {active ? 'Deactivate' : 'Activate'}
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <th>Rent</th>
                              <td>
                                {alert.rent !== 100000
                                  ? `< ${alert.rent} DKK`
                                  : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th>Rooms</th>
                              <td>
                                {alert.rooms ? `> ${alert.rooms} rooms` : '-'}
                              </td>
                            </tr>
                            <tr>
                              <th>Size</th>
                              <td>{alert.size ? `> ${alert.size} m2` : '-'}</td>
                            </tr>
                            <tr>
                              <th>Updated</th>
                              <td>
                                {alert.updatedAt &&
                                  formatDateStr(alert.updatedAt)}
                              </td>
                            </tr>
                            <tr>
                              <th>Created</th>
                              <td>
                                {alert.createdAt &&
                                  formatDateStr(alert.createdAt)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </BootPanel>
                    );
                  })}

                  {activeAlerts ? (
                    <Button
                      block
                      bsStyle="danger"
                      onClick={this.deactivateAlerts}
                      style={{ marginBottom: 10 }}
                    >
                      Deactivate all alerts
                    </Button>
                  ) : null}
                </BootPanel>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  // query
  graphql(userQuery, {
    options: (props) => ({
      variables: {
        userId: Number(props.params.userId)
      }
    }),
    props: ({ ownProps, data: { loading, user } }) => {
      return {
        ...ownProps,
        loading,
        user
      };
    }
  }),
  // mutate user
  graphql(mutateUserQuery, {
    options: (props) => ({
      variables: {
        userId: Number(props.params.userId),
        data: {}
      }
    }),
    props: ({ ownProps, mutate }) => ({
      ...ownProps,
      deleteUser: async({ reason }: { reason: string } = { reason: '' }) => await apiClient.post('/api/account/deleteUser', { data: { id: ownProps.params.userId, reason } }),
      undoDelete: async({ reason }: { reason: string } = { reason: '' }) => await apiClient.post('/api/account/undoDelete', { data: { id: ownProps.params.userId, reason } }),
      updateUser: (data) =>
        mutate({
          variables: { data },
          updateQueries: {
            User: (prev, args) => {
              const { mutationResult } = args;
              return update(prev, {
                $merge: { user: mutationResult.data.updateUser }
              });
            }
          }
        })
    })
  }),
  // mutate alerts
  graphql(mutateAlertQuery, {
    props: ({ ownProps, mutate }) => ({
      ...ownProps,
      updateAlert: (alertId, data) =>
        mutate({
          variables: { alertId, data },
          updateQueries: {
            User: (prev, args) => {
              const { mutationResult } = args;
              const next = mutationResult.data.updateAlert;
              let idx = null;
              for (let i = 0, len = prev.user.alerts.length; i < len; i++) {
                if (prev.user.alerts[i].id === next.id) {
                  idx = i;
                  break;
                }
              }
              const mutation =
                idx !== null ? { [idx]: { $set: next } } : { $push: [next] };
              return update(prev, { user: { alerts: mutation } });
            }
          }
        })
    })
  })
)(AkutboligUser);
