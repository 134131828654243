import React from 'react';
import apiClient from 'app/utils/api-client';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Button from 'app/components/mui/Button';
import Table from 'app/components/mui/Table';
import MatchModal from 'app/common/matchModal';

type Props = {
  dense?: boolean;
  children: any;
  title?: string;
  style?: { [key: string]: any };
};

const ONE_DAY = 86400000;

const columns = [
  { label: 'Key', name: 'key', valueType: 'custom', customValue: (value) => String(value) },
  { label: 'LTV sum', name: 'sum', valueType: 'number' },
  { label: '% of total', name: 'percentage', valueType: 'number' },
  { label: 'Sub. Count', name: 'subCount', valueType: 'number' },
  { label: 'Avg. LTV', name: 'average', valueType: 'number' },
];

function parseDate(date) {
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1 : `0${date.getMonth()+1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    return `${date.getFullYear()}-${month}-${day}`;
}

export const SubscriptionLTVReport = (props: Props): JSX.Element => {
    const now = new Date();
    const past = new Date(new Date().getTime() - 90 * ONE_DAY);
    const [list, setList] = React.useState([]);
    const [from, setFrom] = React.useState(parseDate(past));
    const [to, setTo] = React.useState(parseDate(now));

    const [totalSum, setTotalSum] = React.useState(0);
    const [totalSubs, setTotalSubs] = React.useState(0);
    const [totalGroup, setTotalGroup] = React.useState(0);
    const [key, setKey] = React.useState('city');
    const [match, setMatch] = React.useState(new Map<string, string>([]));

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        const filtered = Array.from(match).map((v) => ({ key: v[0], value: v[1] })).filter((v) => v.value !== null);
         apiClient.post('/api/microservice', { data: { action: 'getLTVReport', data: { filter: { from: from, to: to, key: key, match: filtered } } } }).then((data) => {
          if(data) {
            const { totalValue, totalSubCount, totalGroups, report } = data;
            setList(report);
            setTotalSum(totalValue);
            setTotalSubs(totalSubCount);
            setTotalGroup(totalGroups);
          }
          setLoading(false);
        }).catch(err => setLoading(false));
    };

  return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>From: </span>
            <TextField type="date" id="from" onChange={(ev) => setFrom(ev.target.value)} value={from} key="from" />
            <span>To: </span>
            <TextField type="date" id="to" onChange={(ev) => setTo(ev.target.value)} value={to} key="to" />
            <span>Key: </span>
            <Select value={key} onChange={(ev) => setKey(ev.target.value)}>
              <MenuItem value="city">City</MenuItem>
              <MenuItem value="subscriptionPlan">Plan</MenuItem>
              <MenuItem value="subscriptionProvider">Provider</MenuItem>
              <MenuItem value="notificationSource">Notification Source</MenuItem>
              <MenuItem value="rooms">Rooms</MenuItem>
              <MenuItem value="size">Size</MenuItem>
              <MenuItem value="source">Source</MenuItem>
              <MenuItem value="sourceId">Source ID</MenuItem>
              <MenuItem value="subscriptionId">Subscription</MenuItem>
              <MenuItem value="weekday">Weekday</MenuItem>
              <MenuItem value="accountId">User</MenuItem>
              <MenuItem value="leaseId">Lease</MenuItem>
              <MenuItem value="fromFacebook">From Facebook</MenuItem>
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MatchModal 
              title="Create filter to match"
              selections={[
                'city',
                'roomsLTE',
                'roomsGTE',
                'sizeLTE',
                'sizeGTE',
                'lifetimevalueLTE',
                'lifetimevalueGTE',
                'source',
                'subscriptionProvider',
                'paymentMethods',
                'notificationSource',
                'sourceId',
                'zip',
                'subscriptionPlan',
                'weekday',
                'leaseId',
                'accountId',
                'subscriptionId',
                'notificationIndirectSource',
                'fromFacebook',
                'subscriptionActive'
              ]}
              values={match}
              onSubmit={(values) => setMatch(values)}
              textBefore
            />
            <Button style={{ margin: '6px' }} size={'small'} label={'Refresh'} onClick={() => refresh()}/>
          </div>
        </div>
        { loading ? <p>Loading...</p> : !list?.length ? <p>No subscription stats</p> : ( <Table
            title={`LTV Report (TotalSum: ${totalSum} | TotalSubs: ${totalSubs} | TotalGroups: ${totalGroup})`}
            columns={columns}
            rows={list}
        />)}
    </div>
  );
};
