import apiClient from 'app/utils/api-client';

const GRAPH_ENDPOINT = '/api/biz/linktool';

export async function getData({
  query,
  variables
}: {
  query: string;
  variables: { [index: string]: any };
}) {
  try {
    const body = await apiClient.graph(GRAPH_ENDPOINT, { query, variables });
    const { data } = body;
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const apiFileUpload = async (
  files,
  data: { encoding?: 'utf8' | 'utf16le' } = {},
  category?: string,
  overwrite?: boolean
) => {
  try {
    const formData = new FormData();
    // formData.append('applyRule', 'false');  // is true by default
    // formData.append('createRule', 'false'); // is true by default
    formData.append('encoding', data.encoding || 'utf8');
    for (const file of files) {
      formData.append('files', file);
    }
    const res = await apiClient.form(`${GRAPH_ENDPOINT}/upload${category ? '/' + category : ''}${overwrite ? '/overwrite' : ''}`, formData);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
