import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

function copypaste(value) {
  navigator.clipboard.writeText(value);
  alert('Copied the text: ' + value);
}
export default class SessionRow extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <tr>
         <td>{this.props.item.id}</td>
         <td>
          <Table striped condensed responsive hover fill>
              <tbody>
                {this.props.item.google?.length ? (<tr>
                  <th>Google Pathname</th>
                  <th>Google ClickID</th>
                  <th>Google Created</th>
                </tr>) : null}
                {this.props.item.google.map((t) => (
                  <tr>
                    <td>{t?.pathname}</td>
                    <td><button onClick={() => copypaste(t?.gclid)}>copy to clipboard</button></td>
                    <td>{t?.createdAt ? new Date(t.createdAt).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen' }) : '(no value)'}</td>
                  </tr>
                ))}
                {this.props.item.utm?.length ? (<tr>
                  <th>UTM Source</th>
                  <th>UTM Medium</th>
                  <th>UTM Campaign</th>
                  <th>UTM Term</th>
                  <th>UTM Content</th>
                  <th>UTM Pathname</th>
                  <th>UTM Created</th>
                </tr>) : null}
                {this.props.item.utm.map((t) => (
                  <tr>
                    <td>{t?.source}</td>
                    <td>{t?.medium}</td>
                    <td>{t?.campaign}</td>
                    <td>{t?.term}</td>
                    <td>{t?.content}</td>
                    <td>{t?.pathname}</td>
                    <td>{t?.createdAt ? new Date(t.createdAt).toLocaleString('da-DK', { timeZone: 'Europe/Copenhagen' }) : '(no value)'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
         </td>
         <td>{this.props.item.timestamp}</td>
      </tr>
    );
  }
}
