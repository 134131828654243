import React from 'react';
import CustomModal from './modal';
import Button from 'app/components/mui/Button';
import MatchSelection from './selection';

type Props = {
  title: string;
  selections: string[];
  values: Map<string, string>;
  textBefore?: boolean;
  textAfter?: boolean;
  onSubmit: (values: Map<string,string>) => void;
};

const MatchModal = (props: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [values, setValues] = React.useState(props.values || new Map<string, string>([]));

  const onChange = (name, value) => {
    setValues(values.set(name, value));
  };

  const formatText = () => {
    return Array.from(values)
    .filter((val) => val[1])
    .map((v) => `${v[0].toUpperCase().replace(/(LTE|GTE)/, '')}${/LTE/.test(v[0]) ? ' <= ' : /GTE/.test(v[0]) ? ' >= ' : ':' }${v[1]}`)
    .join(', ');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {props.textBefore ? <p style={{ margin: '0px', marginRight: '10px', marginLeft: '10px' }}>{formatText()}</p> : null}
      <Button style={{ margin: '6px' }} size={'small'} label={'Filter'} onClick={() => setShowModal(true)}/>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <h2 id="simple-modal-title" style={{ marginBottom: '1em' }}>
          {props.title}
        </h2>
        <hr/>
        {
          props.selections.map((v) => <MatchSelection
              name={v}
              value={values?.get(v) || null}
              onChange={onChange}
              reset={onChange}
            />
          )
        }
        <hr/>
        <Button style={{ margin: '6px' }} size={'small'} label={'Submit'} onClick={() => {
          props.onSubmit(values);
          setShowModal(false);
        }}/>
      </CustomModal>
      {props.textAfter ? <p style={{ margin: '0px', marginRight: '10px', marginLeft: '10px' }}>{formatText()}</p> : null}
    </div>
  );
};

export default MatchModal;
